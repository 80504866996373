.project-body{
    min-height: 100vh;
    height: fit-content;
    position: absolute;
    width: 100%;
}

.nav-back{
    width:fit-content;
    border-radius: 50%;
    top:20px;
    left:20px;
    position: fixed;
    background-color:#242424;
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);

    display: flex;
    align-items: center;
    z-index: 2;
}

.back-arrow{
    width:50px;
    transition: background-color 1s ease-in-out;
    border-radius: 100%;
}

.back-arrow:hover{
    cursor: pointer;
    background-color: #474747;
}

.project-name{
    margin: 0 20px 0px 0;
    padding: 0;
    color:white;
}

.project-content{
    margin: auto;
    width:65%;
    margin-top: 100px;

    color: white;
    background-color:#242424BB;
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    border-radius: 50px;
    padding: 30px;

    display: block;
    overflow: auto;
}

.project-blob{
    display: flex;
    max-width: 80%;
    position: absolute;
    top: 0;
    right:0;
    max-height: 100vh;
    z-index: -1;
    transform: scaleY(-100%);
    filter: drop-shadow(0 -10px 2px rgb(0 0 0 / 0.7));

    
}

.bottom-blob-project{
    display: flex;
    max-width: 80%;
    position: absolute;
    bottom: 0;
    left:0;
    max-height: 100vh;
    z-index: -1;
    transform: scaleX(-100%);
    filter: drop-shadow(0 -10px 2px rgb(0 0 0 / 0.7));
    
}

.long-desc{
    width: fit-content;
    font-size:20px;
}

.embeddable-iframe{
    background-color: white;
    height:1000px;
}

.p-desc{
    display: block;
    margin-top:20px;
}

@media only screen and (max-width: 800px){
    
    .project-content{
        width:80%;
    }
}