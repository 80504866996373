.skills-used{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.skills-used .skill-chip{
    padding:5px 15px;
    background-color: #6b6b6b;
    border-radius: 6px;
    height:fit-content;
    width: fit-content;
    margin:5px 0px;
}

.skills-used .skill-chip:not(:last-child){
    margin-right:10px;
}