body{
    background-color: #242424;
}


.first-page{
    width:100%;
    height:100svh;
    display: grid;
    justify-content: center;
    align-items: center;
    padding: 0;
    position: relative;
    
}

/* Header Title */

.header{
    color:white;
    text-align: center;
    display: block;
}
.header-name{
    font-size: 40px;
    display: block;
    margin-bottom: 7px;
}
.header-title{
    font-size:30px;
    font-weight: bold;
}

.header-position, .header{
    filter: drop-shadow(0 5px 2px rgb(0 0 0 / 0.7));
}
.header-position{
    font-size: 25px;
    margin-bottom: 75px;
    display: block;
}

/* Navigation */

.nav ul{
    list-style-type: none;
    display: flex;
    justify-content: center;
    padding: 0;
}

.nav ul li:not(:last-child){
    margin-right: 10px;
}

.nav ul li{
    padding: 5px 20px;
    border-radius: 100px;
    color:white;
    font-size:18px;
    cursor: pointer;
    height:fit-content;
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.1);
}

.nav ul li:nth-child(1){
    background-color: #515AAC;
}
.nav ul li:nth-child(2){
    background-color: #51AC86;
}
.nav ul li:nth-child(3){
    background-color: #AC5151;
}

.arrow-down:hover{
    cursor: pointer;
}

.arrow-down{
    position: absolute;
    top:50%;
    left:50%;
    width: 50px;
    height:50px;
    transform: translate(-50%,180px);
    background-color: #242424;
    color: white;
    border-radius: 50%;
    filter: drop-shadow(0 3px 2px rgb(0 0 0 / 0.7));
}
.arrow-down img{
    display: block;
    margin: auto;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
}

/* Blob styles */

.bottom-blob{
    display: flex;
    max-width: 80%;
    position: absolute;
    bottom: 0;
    margin-bottom: -1px;
    right:0;
    max-height: 100vh;
    z-index: -1;
    filter: drop-shadow(0 -10px 2px rgb(0 0 0 / 0.1));
}

.bottom-arrow{
    display: flex;
    max-width: 80%;
    position: absolute;
    bottom: 0;
    right:0;
    max-height: 100vh;
    z-index: -1;
}

.top-blob{
    display: flex;
    max-width: 75%;
    position: absolute;
    top: 0;
    max-height: 100vh;
    z-index: -1;
    filter: drop-shadow(3px 10px 2px rgb(0 0 0 / 0.1));
    filter: blur(2px);
}

.iframe-cali{
    background-color: white;
}

.email{
    width:fit-content;
    margin-left: 0;

}
.contact-modal{
    max-width: 95%;
    width: 200px;
    position: fixed;
    padding:20px;
    border-radius: 20px;
    background-color: #242424;
    filter: drop-shadow(3px 10px 2px rgb(0 0 0 / 0.7));
    top:20%;
    left:50%;
    transform: translate(-50%,-50%);
    color: white;
    border:5px solid black;
    display: none;
}

.background-fade{
    filter: blur(1px);
    -webkit-filter: blur(1px);
    top:0px;
    left:0px;
    position: absolute;
    width:100%;
    height:100vh;
    opacity: 0.6;
    background-color: #242424;
    display: none;
}

.contact-close{
    display: grid;
    width:fit-content;
    background-color: #242424;
    filter: drop-shadow(3px 10px 2px rgb(0 0 0 / 0.7));
    padding: 10px 20px;
    float:right;
    border-radius: 20px;
    margin-top: 20px;
    transition: background-color 1s ease-in-out;
}
.contact-modal a {
    color:white;
}

.contact-close:hover{
    cursor: pointer;
    background-color: #474747;
}