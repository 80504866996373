.footer-body{
    width:100%;
    height: 200px;
    position: relative;
    margin-top: 150px;
}

.footer-blob{
    display: flex;
    max-width: 80%;
    bottom: 0;
    position: absolute;
    max-height: 100vh;
    z-index: -1;
    right:0;
    transform: rotateZ(-100%);
    filter: drop-shadow(0 -10px 2px rgb(0 0 0 / 0.1));
}

.arrow-to-top img{
    display: block;
    margin: auto;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
}

.arrow-to-top{
    position: absolute;
    top:50%;
    left:50%;
    width: 50px;
    height:50px;
    transform: translate(-50%) rotateZ(180deg);
    background-color: #242424;
    color: white;
    border-radius: 50%;
    box-shadow: 0 -10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
}

.arrow-to-top:hover{
    cursor: pointer;
}