.about-me-body{
    min-height: 100svh;
    height: fit-content;
    position: relative;
    display: grid;
    justify-content: center;
    align-items: center;
}

.about-me-blob-1{
    display: flex;
    max-width: 80%;
    position: absolute;
    top: 0;
    right:0;
    max-height: 100vh;
    z-index: -1;
    transform: rotateZ(-100%);
    transform: scaleY(-100%);
}

.i-am{
    filter: drop-shadow(0 5px 2px rgb(0 0 0 / 0.7));
}

.about-me-blob-2{
    display: flex;
    max-width: 80%;
    position: absolute;
    bottom: 0;
    left:0;
    margin-bottom:-2px;
    max-height: 100vh;
    z-index: -1;
    transform: scaleX(-100%);
}

.about-me-desc h1{
    width: 50%;
    margin: auto;
    font-size:40px;
}

.about-me-desc{
    width:100%;
    text-align: left;
    color:white;
}

.about-me-desc-2{
    width:50%;
    margin: auto;
}
.about-me-desc-2 ul{
    text-align: left;
    border-left: 5px solid #5e5e5e;
    list-style-type: none;
    padding-left: 20px;
}

.about-me-desc-2 ul li{
    margin-top: 20px;
    padding: 5px 20px;
    width: fit-content;
    border-radius: 20px;
    font-size: 20px;
}

.game-dev{
    display:inline;
    white-space:nowrap;
}


.arrow-down-abt-me:hover{
    cursor: pointer;
}

.arrow-down-abt-me{
    width: 50px;
    height:50px;
    margin: 70px auto;
    background-color: #242424;
    color: white;
    border-radius: 50%;
    filter: drop-shadow(0 3px 2px rgb(0 0 0 / 0.7));
}
.arrow-down-abt-me img{
    display: block;
    margin: auto;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
}


@media only screen and (max-width:1100px){
    .about-me-desc-2, .about-me-desc h1{
        width: 80%;
    }
}

@media only screen and (max-width:600px){
    .about-me-desc-2, .about-me-desc h1{
        width: 80%;
    }
}


@media only screen and (max-width:700px){
    .about-me-desc-2 ul li{
        font-size: 15px;
    }
}