.media-slideshow{
    display: grid;
    grid-template-areas: "media"
                         "controls";
    grid-template-columns: 1fr;
    margin-top: 20px;
    width: 50%;
    margin: auto;
    position: relative;
    float:left;
    margin-right: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
}
.media-left, .media-right{
    display: grid;
    padding: 10px;
    background-color: #242424;
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    border-radius: 100%;
    width: fit-content;
    height: fit-content;
    cursor: pointer;
    margin-top:10px;
    transition: background-color 1s ease-in-out;
}

.media-left img{
    transform: rotateZ(90deg);
    width:20px;
}

.media-left:hover{
    background-color: #474747;
}
.media-right:hover{
    background-color: #474747;
}

.media-right img{
    transform: rotateZ(-90deg);
    width:20px;
}

.media-controls{
    grid-area: controls;
    display: flex;
}

.media-source{
    margin: auto;
    grid-area: media;
    width:100%;
    aspect-ratio: 1;
    background-color: #242424;
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    object-fit: contain;
}
.media-count{
    grid-area: count;
    margin: auto;
    margin-top: 20px;
}

.embeddable-iframe{
    width:100%;
}

@media screen and (max-width:650px){
    .media-slideshow{
        width:100%;
    }
}