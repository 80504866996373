.second-page{
    width:100%;
    min-height:100svh;
    padding: 0;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}


.second-page-container{
    padding: 10px;
    width: fit-content;
    height: auto;
}

.bottom-blob-newest-project{
    z-index: -1;
    max-width: 80%;
    position: absolute;
    top: 0;
    left:0;
    max-height: 100vh;
    transform: scaleX(-100%);
    transform: scaleY(-100%);
    transform: rotateZ(180deg);
    filter: drop-shadow(0 -10px 2px rgb(0 0 0 / 0.1));
}

/* Projects grid */

.project-title{
    color:white;
    /* background-color: #242424; */
    /* padding: 5px 10px; */
    /* border-radius: 20px; */
    margin-top: 250px;
    margin-bottom: 50px;
    font-size:40px;
    display: block;
    text-align: center;
    filter: drop-shadow(0 5px 2px rgb(0 0 0 / 0.7));
}

.project-grid{
    display: grid;
    gap:40px;
    grid-template-columns: 1fr 1fr 1fr;
}

@media only screen and (max-width: 1450px) {
    .project-grid{
        grid-template-columns: 1fr 1fr;
    }
    .project-title{
        margin-top: 200px;
        margin-bottom: 60px;
    }
}

@media only screen and (max-width: 900px) {
    .project-grid{
        grid-template-columns: 1fr;
    }
    .project-title{
        margin-top: 140px;
        text-align: center;
    }
}